<template>
    <div class="container-fluid">
        <div class="row vh-100">
            <main class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="topbottom_padding">
                    <div class="container">
                        <h1>Reset Password</h1>
                        <form action="" @submit.stop.prevent="onSubmit">
                            <div class="block">
                                <div class="whiteimput">
                                    <div class="innerblock">
                                        <div class="form-group">
                                            <b-form-input
                                                type="password"
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                v-model="$v.form.password.$model"
                                                :state="validateState('password')"
                                                aria-describedby="passwordHelp"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="emailHelp"
                                                >Password is
                                                required</b-form-invalid-feedback
                                            >
                                        </div>
                                        <div class="form-group">
                                            <b-form-input
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                v-model="$v.form.confirmPassword.$model"
                                                :state="validateState('confirmPassword')"
                                                aria-describedby="confirmPasswordHelp"
                                            ></b-form-input>
                                            <b-form-invalid-feedback
                                                id="confirmPasswordHelp"
                                                >Password did not
                                                match</b-form-invalid-feedback
                                            >
                                        </div>
                                    </div>
                                </div>
                                <p class="password_text">Passwords must be a minimum of 8 characters and include at least 1 uppercase, 1 lowercase and 1 number.</p>
                            </div>
                            <button type="submit" class="btn">Submit</button>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
export default {
    mixins: [validationMixin],
    components: { BFormInput, BFormInvalidFeedback },
    data() {
        return {
            form: {
                password: '',
                confirmPassword: '',
            },
            key: null,
        }
    },
    validations: {
        form: {
            password: {
                required,
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs(function () {
                    return this.form.password
                }),
            },
        },
    },
    created() {
        if('key' in this.$route.query) {
            this.key = this.$route.query.key;
        }
    },
    methods: {
        validateState(name) {
            return this.$helper.validateState(name, this)
        },
        async onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return
            }
            this.$http.post('/reset-password', {
                key: this.key,
                password: this.form.password,
            }).then(resp => {
                if(resp.data.status) {
                    this.$helper.showMessage(resp.data.message)
                    this.$router.replace('/');
                }
            }).catch(e => {
                console.error(e);
            })
        },
    },
}
</script>
